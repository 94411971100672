<template>

  <div class="mode-center2">
    <div class="font-serif textb3 col-span-2 pb-4" v-if="obj.home_download_text">
      {{obj.home_download_text}}
    </div>
    <div class="pb-10">
      <a :href="obj.home_download_file.file" target="_blank" class="border border-cherry px-4 py-2 font-mono textb2 inline-block uppercase text-cherry hover:bg-black hover:text-white hover:border-white duration-300 ease-in-out focus:outline-none">
        {{obj.home_download_button_text}}
      </a>
    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

var lv = {}

export default {
  name: 'block_download',
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>

</style>

