<template>
  <div class="mode-center">


  </div>

</template>

<script>


export default {
  name: 'cookie_',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    /*
    */
      let cookie_script = document.createElement('script')
      cookie_script.setAttribute('id', 'CookieDeclaration')
      cookie_script.setAttribute('src', 'https://consent.cookiebot.com/b85a2034-13db-4452-b701-7f81f59c76f4/cd.js')
      document.head.appendChild(cookie_script)
  },
  methods: {

  }
}
</script>

