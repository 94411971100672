<template>

  <div class="mode-center2">
    <div class="pb-20 text wysiwyg" v-html="obj.home_textfield">
    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

var lv = {}

export default {
  name: 'block_textfield',
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>

</style>

