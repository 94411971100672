<template>

  <div class="mode-center2">
    <div class="pb-20 inline-block">
      <img :src="obj.home_image.md" />
      <div class="bg-black text text-white font-sans textcaption padcaption" v-if="obj.home_image_caption">
        {{obj.home_image_caption}}
      </div>
    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

var lv = {}

export default {
  name: 'block_image',
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>
.padcaption{
  padding: 15px 20px 20px 17px;
  max-width: 800px;
}

</style>

