<template>

  <div class="mode-center">
    <div class="pb-16">

      <div class="grid grid-cols-2 mx900:grid-cols-1">

        <!-- <div class="border-4 border-black"> -->
        <!--  
        <div class="">
          <vue-plyr ref="videoplayer">
            <div class="plyr__video-embed">
              <iframe
                :src="youtube_pre+obj.home_youtube+youtube_post"
                allowfullscreen
                allowtransparency
                playsinline
              ></iframe>
            </div>
          </vue-plyr>
        </div>
        -->

        <div class="plyr__video-embed js-player" :id="'player_' + mid">
          <iframe
            :src="'https://www.youtube.com/embed/' + obj.home_youtube + '?iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1'"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          ></iframe>
        </div>

        <div class="bg-black text-white p-6 pt-8 mx900:order-first text">
          <div class="font-serif hdrb1 pb-8">
            {{obj.home_youtube_title}}
          </div>
          <div class="textb2">
            {{obj.home_youtube_text}}
          </div>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

import Plyr from 'plyr';

export default {
  name: 'block_youtube',
  props: ['obj'],
  data() {
    return {
      youtube_pre: globals.embed.youtube.pre,
      youtube_post: globals.embed.youtube.post,
      mid: 1
    }
  },
  mounted() {
    console.log(this.$props.obj)
    this.mid = this.$props.obj.id
    this.$nextTick(function () {
      const player = new Plyr('#player_' + this.mid)
    })
    //const player = new Plyr(document.querySelector('.js-player'))
  },
  methods: {
  }
}
</script>

<style>

</style>

