<template>

  <div class="mode-center">
    <div class="p-12"></div>
    <div class="font-mono text hdrsection" :id="obj.home_section_slug">
      {{obj.home_section}}
    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

var lv = {}

export default {
  name: 'block_section',
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style>

</style>

