<template>
  <div>

    <div v-for="(block, index) in blocks" :key="'block_' + index" :style="'background:' + block.color_bg + ';'">
      <block_section :obj="block" v-if="block.type === 'section'" />
      <block_textfield :obj="block" v-if="block.type === 'textfield'" />
      <block_image :obj="block" v-if="block.type === 'image'" />
      <block_gallery :obj="block" v-if="block.type === 'gallery'" />
      <block_youtube :obj="block" v-if="block.type === 'youtube'" />
      <block_download :obj="block" v-if="block.type === 'download'" />
    </div>

  <div class="p-12" :style="'background:' + last_color + ';'"></div>

  </div>
</template>

<script>
import globals from '@/modules/globals'

import block_section from '@/components/blocks/block_section'
import block_textfield from '@/components/blocks/block_textfield'
import block_image from '@/components/blocks/block_image'
import block_gallery from '@/components/blocks/block_gallery'
import block_youtube from '@/components/blocks/block_youtube'
import block_download from '@/components/blocks/block_download'

var lv = {}
lv.thisVue = null

export default {
  name: 'blocks',
  components: {
    block_section,
    block_textfield,
    block_image,
    block_gallery,
    block_youtube,
    block_download,
  },
  props: [],
  data() {
    return {
      blocks: {},
      last_color: '#ffffff'
    }
  },
  mounted() {
    lv.thisVue = this
    this.populate()
  },
  methods: {
    populate(){
      this.blocks = globals.api_data.first_contact.blocks

      var keys = Object.keys(this.blocks)
      var last = keys[keys.length-1]
      this.last_color = this.blocks[last].color_bg
    }
  }
}
</script>

<style>

</style>

