<template>
  <div>

      <blocks />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
const scrollPlugin = ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin)

import blocks from '@/components/blocks'


var lv = {}
lv.thisVue = null

export default {
  name: 'home',
  components: {
    blocks
  },
  data() {
    return {
    }
  },
  mounted() {
    lv.thisVue = this

    this.scroll()

    EventBus.$on('nav_click', (e) => {
      this.scroll_native(e)
    })

  },
  methods: {
    scroll(){
      if(this.$route.params.anchor){
        gsap.to(window, {delay:0.5, duration: 0.5 , scrollTo: {y: '#' + this.$route.params.anchor, offsetY: 105}, ease:'power4.inOut'})
      }
    },
    scroll_instant(){
      if(this.$route.params.anchor){
        gsap.killTweensOf(window)
        gsap.to(window, {duration: 0.8 , scrollTo: {y: '#' + this.$route.params.anchor, offsetY: 105}, ease:'power4.inOut'})
      }
    },
    scroll_native(did){
      gsap.killTweensOf(window)
      gsap.to(window, {duration: 0.8 , scrollTo: {y: '#' + did, offsetY: 105}, ease:'power4.inOut'})
    },

  },
  watch:{
      $route (to, from){
          //console.log(from + ' >>> ' + to)
          this.scroll_instant()
      }
  }
}
</script>

<style>

</style>