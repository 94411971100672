<template>


  <div class="mode-center">
    <div class="pb-20 hdrb1">
      <div class="font-serif pb-10">
        {{obj.home_gallery_title}}
      </div>

      <div class="inline-block align-top" v-for="(item, index) in obj.gallery" :key="'gallery_item_' + index">
        <a :href="item.file" class="glightbox3" data-gallery="gallery1" :data-title="item.title" :data-description="item.description" >
          <img class="galleryimg" :src="item.sm"/>
        </a>
      </div>

      <!-- 
      <div class="inline-block" v-for="(item, index) in obj.gallery" :key="'gallery_item_' + index">
        <img class="galleryimg" :src="item.sm" />
      </div>
       -->
    </div>
  </div>

</template>

<script>
import globals from '@/modules/globals'

import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css'

var lv = {}

export default {
  name: 'block_gallery',
  props: ['obj'],
  data() {
    return {
    }
  },
  mounted() {
    const lightbox = GLightbox({
      'selector': '.glightbox3'
    })
  },
  methods: {
  }
}
</script>

<style>

  .galleryimg{
    height: 236px;
    margin: 0px 36px 36px 0px;
  }
  @media (max-width: 720px){
    .galleryimg{
      max-height: 318px;
      height: auto;
    }
  }

  .glightbox-clean .gslide-title {
    font-family: 'DM Sans';
    /*
    font-size: 1em;
    font-weight: normal;
    color: #000;
    margin-bottom: 19px;
    line-height: 1.4em;
    */
}
.glightbox-clean .gslide-desc {
    font-family: 'DM Sans';
    /*
    font-size: 0.86em;
    margin-bottom: 0;
    font-family: arial;
    line-height: 1.4em;
    */
}

</style>

